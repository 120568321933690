"use client";

import { FC, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarHeart,
  faCartShoppingFast,
  faPaperPlaneTop,
  faTicket,
} from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./OrderProgress.module.css";
import classNames from "classnames";
import Link from "next/link";
import { ShowId } from "@/app/api/schema";

export type OrderProgressStep = "date" | "ticket" | "info" | "success";

export const Item: FC<
  {
    icon: IconProp;
    href: string | null;
    // TODO: currently href === null === markDone, so maybe remove markDone
    markDone: boolean;
    current: boolean;
  } & PropsWithChildren
> = function ({ icon, href, markDone, current, children }) {
  const content = (
    <>
      <div className={styles.circle}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles["label-wrapper"]}>
        <div className={styles.label}>{children}</div>
      </div>
    </>
  );

  if (href) {
    return (
      <Link
        href={href}
        className={classNames(styles.item, {
          [styles.done]: markDone,
          [styles.current]: current,
        })}
      >
        {content}
      </Link>
    );
  } else {
    return (
      <div
        className={classNames(styles.item, {
          [styles.done]: markDone,
          [styles.current]: current,
        })}
      >
        {content}
      </div>
    );
  }
};

export const Connection: FC = function () {
  return <div className={styles.connection} />;
};

export interface Props {
  mode: "regular" | "ivv";
  current: OrderProgressStep;
  selectedShowIds: ShowId[];
}

export const OrderProgress: FC<Props> = function (props) {
  return (
    <div className={classNames(styles.container)}>
      <Item
        href={props.mode === "regular" ? "/" : "/intern"}
        // Basically there isn't any state to signify this, but if we're on any
        // page it means we selected a date, so always mark it done, since
        // current trumps done in regard to importance.
        markDone
        current={props.current === "date"}
        icon={faCalendarHeart}
      >
        Termin
      </Item>
      <Connection />
      <Item
        href={
          (props.mode === "regular" ? "" : "/intern") +
          (props.selectedShowIds.length === 0
            ? // If no shows are selected -> step isn't "done"
              null
            : props.selectedShowIds.length === 1
              ? // If a single show is selected, link directly to it
                "/show/" + props.selectedShowIds[0]
              : // Otherwise link to the show list screen
                "/")
        }
        markDone={props.selectedShowIds.length > 0}
        current={props.current === "ticket"}
        icon={faTicket}
      >
        Tickets
      </Item>
      <Connection />
      <Item
        href={
          props.selectedShowIds.length > 0
            ? props.mode === "regular"
              ? "/kasse"
              : "/intern/kasse"
            : null
        }
        /* TODO: It would be better to actually check if there's data entered
            here or something similar.. */
        markDone={props.selectedShowIds.length > 0}
        current={props.current === "info"}
        icon={faCartShoppingFast}
      >
        Kasse
      </Item>
      <Connection />
      <Item
        href={null}
        current={props.current === "success"}
        // Unless we implement storing previous orders, this will never matter
        markDone={false}
        icon={faPaperPlaneTop}
      >
        Versand
      </Item>
    </div>
  );
};
