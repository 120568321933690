import { FC, useEffect } from "react";
import { useMachine } from "@xstate/react";
import {
  envelopeMachine,
  TicketPosition,
} from "@/app/components/footer/EnvelopeMachine";
import classNames from "classnames";
import styles from "@/app/components/footer/Envelope.module.css";
import Image from "next/image";
import ticket from "@/app/components/footer/anastasia_ticket.webp";
import stamp from "@/app/components/footer/anastasia_stamp.webp";

export interface Props {
  tickets: number;
  addressLabel: string;
  opened: boolean;
  debug?: boolean;
}

export const Ticket: FC<{ position: TicketPosition }> = function (props) {
  return (
    <Image
      className={classNames(styles.ticket, {
        [styles["origin-behind"]]: props.position === "behind",
        [styles["placeholder"]]: props.position === "placeholder",
        [styles["rest"]]: props.position === "rest",
        [styles["fly-out-a"]]: props.position === "fly-out-a",
        [styles["fly-out-b"]]: props.position === "fly-out-b",
        [styles["origin-top"]]: props.position === "top",
      })}
      alt=""
      src={ticket}
    />
  );
};

export const Envelope: FC<Props> = function (props) {
  const [snapshot, send] = useMachine(envelopeMachine, {
    input: {
      open: props.opened,
      tickets: props.tickets,
    },
  });

  useEffect(() => {
    send({
      type: "change_target",
      open: props.opened,
      tickets: props.tickets,
    });
  }, [props.opened, props.tickets, send]);

  const { styleFront, styleTucked, styleTickets } = snapshot.context;

  return (
    <div
      className={classNames(styles.container, {
        [styles.debug]: props.debug,
        [styles["turned-front"]]: styleFront,
        [styles.tucked]: styleTucked,
      })}
    >
      <div className={styles.rotator}>
        <div className={styles.lid}>
          <div className={styles["lid-top"]} />
          <div className={styles["lid-front"]} />
        </div>
        <div className={styles.back}>
          <Image className={styles.stamp} alt="" src={stamp} />
          <div className={styles.window}>
            <pre>{props.addressLabel}</pre>
          </div>
        </div>
        <div className={styles["left-side"]} />
        <div className={styles["right-side"]} />
        <div className={styles["bottom-side"]} />
        <Ticket position={styleTickets[0]} />
        <Ticket position={styleTickets[1]} />
        <Ticket position={styleTickets[2]} />
        <Ticket position={styleTickets[3]} />
        <Ticket position={styleTickets[4]} />
        <Ticket position={styleTickets[5]} />
        <div className={styles.front} />
      </div>
    </div>
  );
};
