import { Brand } from "@/app/lib/typeUtils";

export type Cent = Brand<number, "Cent">;

/**
 * All currency coming from the backoffice API is in the format `1234.56`.
 * Conversion happens in the load/transform functions.
 */
export type BackofficeCurrencyValue = Brand<number, "BackofficeCurrency">;

export const convertCurrencyFromBackoffice = (
  value: BackofficeCurrencyValue,
): Cent => {
  return (value * 100) as Cent;
};

const longCurrencyFormat = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
});
const shortCurrencyFormat = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
});

export const formatCurrency = (value: Cent): string =>
  longCurrencyFormat.format(value / 100);

export const formatShortCurrency = (value: Cent): string =>
  shortCurrencyFormat.format(value / 100);
