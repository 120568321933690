"use client";

import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  FooterState,
  FooterStateContext,
} from "@/app/components/footer/FooterStateProvider";
import styles from "@/app/components/footer/Footer.module.css";
import classNames from "classnames";
import { OrderProgress } from "@/app/components/footer/OrderProgress";
import { FooterCart } from "@/app/components/footer/FooterCart";
import { Envelope } from "@/app/components/footer/Envelope";

export interface Props {
  silhouette: ReactNode;
}

export const FooterClient: FC<Props> = function (props) {
  const state = useContext(FooterStateContext);
  const [heldInfo, setHeldInfo] = useState<FooterState>(state);

  useEffect(() => {
    if (state.footerShown) {
      setHeldInfo(state);
    }
  }, [state]);

  const [envelopeReset, setEnvelopeReset] = useState(1);
  useEffect(() => {
    setEnvelopeReset((v) => v + 1);
  }, [state.footerShown]);

  return (
    <>
      <div
        className={classNames(
          styles.silhouette,
          state.footerShown && styles.shown,
        )}
      >
        {props.silhouette}
      </div>
      <div
        className={classNames(styles.footer, state.footerShown && styles.shown)}
      >
        <div className={styles.content}>
          <div className={styles.progress}>
            <OrderProgress
              mode={state.mode}
              current={state.currentStep}
              selectedShowIds={heldInfo.selectedShows}
            />
          </div>

          <FooterCart
            value={heldInfo.price}
            shows={heldInfo.selectedShows.length}
            tickets={heldInfo.tickets}
            processingFeeInfo={state.processingFeeInfo}
          />
          <Envelope
            key={"env-" + envelopeReset.toString()}
            tickets={heldInfo.tickets}
            addressLabel={heldInfo.addressLabel}
            opened={heldInfo.envelopeOpen}
          />
        </div>
      </div>
    </>
  );
};
