import React, { FC } from "react";
import { type Cent, formatCurrency } from "@/app/lib/currency";
import styles from "./FooterCart.module.css";

export interface Props {
  value: Cent;
  shows: number;
  tickets: number;
  processingFeeInfo: boolean;
}

export const FooterCart: FC<Props> = function (props) {
  return (
    <div className={styles.cart}>
      <div className={styles.price}>{formatCurrency(props.value)}</div>
      {props.processingFeeInfo ? (
        <div className={styles.note}>inkl. Bearbeitungsgebühr</div>
      ) : null}
      <div className={styles.tickets}>
        {props.shows > 1 ? <span>{props.shows} Termine / </span> : null}
        {props.tickets + (props.tickets === 1 ? " Ticket" : " Tickets")}
      </div>
    </div>
  );
};
