import { assertEvent, assign, raise, setup } from "xstate";

export type TicketPosition =
  | "behind"
  | "placeholder"
  | "rest"
  | "fly-out-a"
  | "fly-out-b"
  | "top";

export type TicketPositions = Readonly<
  [
    TicketPosition,
    TicketPosition,
    TicketPosition,
    TicketPosition,
    TicketPosition,
    TicketPosition,
  ]
>;

const specialCaseLUT: TicketPositions[] = [
  ["top", "top", "top", "top", "top", "top"],
  ["rest", "top", "top", "top", "top", "top"],
  ["rest", "fly-out-a", "top", "top", "top", "top"],
  ["rest", "fly-out-a", "fly-out-b", "top", "top", "top"],
];

const ticketPositionLUT: TicketPositions[] = [
  ["placeholder", "rest", "fly-out-a", "fly-out-b", "top", "behind"],
  ["behind", "placeholder", "rest", "fly-out-a", "fly-out-b", "top"],
  ["top", "behind", "placeholder", "rest", "fly-out-a", "fly-out-b"],
  ["fly-out-b", "top", "behind", "placeholder", "rest", "fly-out-a"],
  ["fly-out-a", "fly-out-b", "top", "behind", "placeholder", "rest"],
  ["rest", "fly-out-a", "fly-out-b", "top", "behind", "placeholder"],
];

export function ticketPositions(tickets: number): TicketPositions {
  switch (tickets) {
    case 0:
    case 1:
    case 2:
    case 3:
      return specialCaseLUT[tickets];
    default:
      return ticketPositionLUT[(tickets - 4) % 6];
  }
}

export const envelopeMachine = setup({
  types: {
    context: {} as {
      currentOpen: boolean;
      currentTickets: number;
      targetOpen: boolean;
      targetTickets: number;
      styleFront: boolean;
      styleTucked: boolean;
      styleTickets: TicketPositions;
    },
    events: {} as
      | { type: "change_target"; open: boolean; tickets: number }
      | { type: "add_ticket" }
      | { type: "remove_ticket" }
      | { type: "open_envelope" }
      | { type: "close_envelope" }
      | { type: "no_next_step" },
    input: {} as { open: boolean; tickets: number },
  },
  delays: {
    transition_ticket: 300,
    transition_untuck: 500,
    transition_turn: 500,
  },
  actions: {
    set_style_ticket_based_on_context: assign({
      styleTickets: ({ context }) => ticketPositions(context.currentTickets),
    }),
    set_style_back: assign({
      styleFront: false,
    }),
    set_style_untucked: assign({
      styleTucked: false,
    }),
    set_style_front: assign({
      styleFront: true,
    }),
    set_style_tucked: assign({
      styleTucked: true,
    }),
    set_target: assign(({ event }) => {
      assertEvent(event, "change_target");
      return {
        targetOpen: event.open,
        targetTickets: event.tickets,
      };
    }),
    set_current_add_ticket: assign({
      currentTickets: ({ context }) => context.currentTickets + 1,
    }),
    set_current_remove_ticket: assign({
      currentTickets: ({ context }) => context.currentTickets - 1,
    }),
    set_current_open: assign({
      currentOpen: true,
    }),
    set_current_closed: assign({
      currentOpen: false,
    }),
    dequeue: raise(({ context }) => {
      if (
        context.currentTickets !== context.targetTickets &&
        !context.currentOpen
      ) {
        return { type: "open_envelope" as const };
      }

      if (context.currentTickets < context.targetTickets) {
        return { type: "add_ticket" as const };
      }

      if (context.currentTickets > context.targetTickets) {
        return { type: "remove_ticket" as const };
      }

      if (context.currentOpen !== context.targetOpen) {
        if (context.targetOpen) {
          return { type: "open_envelope" as const };
        } else {
          return { type: "close_envelope" as const };
        }
      }

      return { type: "no_next_step" as const };
    }),
  },
  guards: {
    envelope_empty: function ({ context }) {
      // Add your guard code here
      return context.currentTickets === 0;
    },
  },
  schemas: {
    events: {
      add_ticket: {
        type: "object",
        properties: {},
      },
      no_next_step: {
        type: "object",
        properties: {},
      },
      change_target: {
        type: "object",
        properties: {
          open: {
            type: "boolean",
            schema: {
              type: "boolean",
            },
          },
          tickets: {
            type: "number",
            schema: {
              type: "number",
            },
          },
        },
      },
      open_envelope: {
        type: "object",
        properties: {},
      },
      remove_ticket: {
        type: "object",
        properties: {},
      },
      close_envelope: {
        type: "object",
        properties: {},
      },
    },
    context: {
      styleFront: {
        type: "boolean",
        description: "",
      },
      targetOpen: {
        type: "boolean",
        description: "",
      },
      currentOpen: {
        type: "boolean",
        description: "",
      },
      styleTucked: {
        type: "boolean",
        description: "",
      },
      styleTickets: {
        type: "array",
        description: "",
      },
      targetTickets: {
        type: "number",
        description: "",
      },
      currentTickets: {
        type: "number",
        description: "",
      },
    },
  },
}).createMachine({
  context: ({ input }) => ({
    currentOpen: input.open,
    currentTickets: input.tickets,
    targetOpen: input.open,
    targetTickets: input.tickets,
    styleFront: !input.open,
    styleTucked: !input.open,
    styleTickets: ticketPositions(input.tickets),
  }),
  id: "Envelope",
  type: "parallel",
  states: {
    Animator: {
      initial: "Idle",
      states: {
        Idle: {
          on: {
            change_target: {
              target: "ReadyForDequeue",
              actions: {
                type: "set_target",
              },
            },
          },
        },
        ReadyForDequeue: {
          always: {
            target: "Dequeued",
            actions: {
              type: "dequeue",
            },
          },
        },
        Dequeued: {
          on: {
            add_ticket: {
              target: "AddTicketAnimation",
              actions: {
                type: "set_current_add_ticket",
              },
            },
            remove_ticket: {
              target: "RemoveTicketAnimation",
              actions: {
                type: "set_current_remove_ticket",
              },
            },
            open_envelope: [
              {
                target: "OpenEmptyEnvelopeAnimation",
                actions: {
                  type: "set_current_open",
                },
                guard: {
                  type: "envelope_empty",
                },
              },
              {
                target: "OpenEnvelopeAnimation",
                actions: {
                  type: "set_current_open",
                },
              },
            ],
            close_envelope: [
              {
                target: "CloseEmptyEnvelopeAnimation",
                actions: {
                  type: "set_current_closed",
                },
                guard: {
                  type: "envelope_empty",
                },
              },
              {
                target: "TuckTicketsAnimation",
                actions: {
                  type: "set_current_closed",
                },
              },
            ],
            no_next_step: {
              target: "Idle",
            },
          },
        },
        AddTicketAnimation: {
          after: {
            transition_ticket: {
              target: "ReadyForDequeue",
            },
          },
          entry: {
            type: "set_style_ticket_based_on_context",
          },
        },
        RemoveTicketAnimation: {
          after: {
            transition_ticket: {
              target: "ReadyForDequeue",
            },
          },
          entry: {
            type: "set_style_ticket_based_on_context",
          },
        },
        OpenEmptyEnvelopeAnimation: {
          after: {
            transition_turn: {
              target: "ReadyForDequeue",
            },
          },
          entry: [
            {
              type: "set_style_back",
            },
            {
              type: "set_style_untucked",
            },
          ],
        },
        OpenEnvelopeAnimation: {
          after: {
            transition_turn: {
              target: "UntuckTicketsAnimation",
            },
          },
          entry: {
            type: "set_style_back",
          },
        },
        CloseEmptyEnvelopeAnimation: {
          after: {
            transition_turn: {
              target: "ReadyForDequeue",
            },
          },
          entry: [
            {
              type: "set_style_front",
            },
            {
              type: "set_style_tucked",
            },
          ],
        },
        TuckTicketsAnimation: {
          after: {
            transition_turn: {
              target: "CloseEnvelopeAnimation",
            },
          },
          entry: {
            type: "set_style_tucked",
          },
        },
        UntuckTicketsAnimation: {
          after: {
            transition_untuck: {
              target: "ReadyForDequeue",
            },
          },
          entry: {
            type: "set_style_untucked",
          },
        },
        CloseEnvelopeAnimation: {
          after: {
            transition_untuck: {
              target: "ReadyForDequeue",
            },
          },
          entry: {
            type: "set_style_front",
          },
        },
      },
    },
    BackgroundReceiver: {
      on: {
        change_target: {
          target: "BackgroundReceiver",
          actions: {
            type: "set_target",
          },
          description:
            "No matter which state we're in, we always update the target object in the context. This is a catch-all, if a state doesn't provide a more specific transition.",
        },
      },
    },
  },
});
